import React from "react";
import Section from "./components/Section";
import EventLocation from "./components/EventLocation";
import Countdown from "./components/Countdown";
import EngagementPic from "./components/assets/collage.png";
import ScrollToTopButton from "./components/ScrollToTopButton.js";
import FlippingCard from "./components/FlippingCard.js";
import ScrollAnimation from "./components/ScrollAnimation.js"; 

function App() {
  const targetDate = "2024-12-23T15:00:00";

  const cardData = [
    { frontText: "Where's our first date?", backText: "Art Fair, Makati City" },
    { frontText: "What's our must order meal?", backText: "Truffle pasta" },
    { frontText: "Who made the first move?", backText: "Carlo ;)" },
    { frontText: "What's our favorite past time?", backText: "Trying out new coffee shops" },
    { frontText: "Where's our first trip together?", backText: "Panglao, Bohol" },
  ];

  return (
    <div>
      {}
      <ScrollAnimation />

      <Section id="main-page" className="main-page">
        <div className="content">
          <div className="header-text">
            <div className="line1">We’re Becoming Mr. & Mr.!</div>
          </div>
          <div className="names">
            <span>Carlo</span>
            <span className="separator"></span>
            <span>Jerome</span>
          </div>
          <div className="date">
            <span className="date-line color-maroon">December 23, 2024</span>
            <span className="date-line">Kitsilano Beach, Vancouver</span>
            <span className="date-line">1:45 PM, Pacific Time</span>
          </div>
        </div>
      </Section>

      <Section id="countdown-section" className="countdown-section">
        <div className="scroll-triggered">
          <Countdown targetDate={targetDate} />
        </div>
      </Section>

      <section id="engagement-section" className="engagement-section">
        <div className="engagement-container">
          <div className="engagement-text scroll-triggered">
            <div className="title">
              We’re beyond excited to have you with us on our big day—whether
              from Vancouver or anywhere in the world!
            </div>
            <div className="iframe-container scroll-triggered">
              <iframe
                style={{ borderRadius: "12px", marginTop: "5vh" }}
                src="https://open.spotify.com/embed/playlist/1Dr0mje8baAtw5rUlL0ztu?utm_source=generator"
                width="100%"
                height="152"
                frameBorder="0"
                allowFullScreen
                title="spotify"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
            </div>
          </div>
          <div className="engagement-image scroll-triggered">
            <img src={EngagementPic} alt="engagement collage photos" />
          </div>
        </div>
      </section>

      <Section id="event-section" className="event-section">
        <div className="scroll-triggered">
          <EventLocation />
        </div>
      </Section>

      <section id="fun-facts-section" className="fun-facts-section">
        <div className="fun-facts-title scroll-triggered">Fun Facts</div>
        <div className="fun-facts-container">
          {cardData.map((card, index) => (
            <FlippingCard
              key={index}
              frontText={card.frontText}
              backText={card.backText}
              className="scroll-triggered"
            />
          ))}
        </div>
      </section>

      <Section id="footer" className="footer">
        <div className="scroll-triggered">
          {}
        </div>
      </Section>
      <ScrollToTopButton />
    </div>
  );
}

export default App;