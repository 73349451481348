import React from 'react';
import './Section.css';

const Section = ({ id, className, children }) => {

    return (
        <section id={id} className={`section ${className}`}>
            <div className="section-content">{children}</div>
        </section>
    );
};

export default Section;