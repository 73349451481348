import React from 'react';
import './EventLocation.css';
import { DateTime } from 'luxon';

const EventLocation = () => {
    const getLocalDate = () => {
        const pacificTime = DateTime.fromISO('2024-12-23T13:45:00', { zone: 'America/Los_Angeles' });
        const localTime = pacificTime.setZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
        return localTime.toFormat('MMMM d, yyyy');
    };
    
    const getLocalTimeWithCity = () => {
        const pacificTime = DateTime.fromISO('2024-12-23T13:45:00', { zone: 'America/Los_Angeles' });
        const localTime = pacificTime.setZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
        return `${localTime.toFormat('h:mm a')} (${localTime.zoneName})`; 
    };

    return (
        <div className="event-location section align-top">
            <span className="section-title">celebrate with us
            from anywhere in the world!</span>
            <div className="event-container">
                <div className="event-item">
                    <iframe
                        title="Ceremony Location"
                        src="https://snazzymaps.com/embed/666456"
                        className="event-map"
                    ></iframe>
                    <div className="event-details">
                        <h3>Ceremony</h3>
                        <p>1:45 PM</p>
                        <p>1106 Maple St.</p>
                        <p>Kitsilano, Vancouver</p>
                    </div>
                </div>

                <div className="event-item">
                    <iframe
                        title="Dinner Location"
                        src="https://snazzymaps.com/embed/666464"
                        className="event-map"
                    ></iframe>
                    <div className="event-details">
                        <h3>Venue</h3>
                        <p>5:00 PM</p>
                        <p>615 Seymour St.</p>
                        <p>Gotham Steakhouse</p>
                    </div>
                </div>
                <div className="event-item">
                    <div className="event-details">
                        <h3>LIVESTREAM</h3>
                        <p>{getLocalDate ()}</p>
                        <p>{getLocalTimeWithCity()}</p>
                        <a 
                            href="https://calndr.link/event/SC5J72ncww" 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="livestream-button"
                        >
                            Add To Calendar
                        </a>
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventLocation;
