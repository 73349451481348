import { useEffect } from "react";

const ScrollAnimation = () => {
  useEffect(() => {
    const elements = document.querySelectorAll(".scroll-triggered");

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target); 
          }
        });
      },
      {
        threshold: 0.1, 
        rootMargin: "0px 0px -10% 0px", 
      }
    );

    elements.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, []);

  return null;
};

export default ScrollAnimation;