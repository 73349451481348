import React from 'react';
import './FlippingCard.css'; // Separate CSS file for styling

const FlippingCard = ({ frontText, backText }) => {
    return (
        <div className="card">
            <div className="card-inner">
                <div className="card-front">
                    {frontText}
                </div>
                <div className="card-back">
                    {backText}
                </div>
            </div>
        </div>
    );
};

export default FlippingCard;
