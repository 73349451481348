import React, { useState, useEffect, useCallback } from 'react';
import './Countdown.css';

const Countdown = ({ targetDate }) => {
    const calculateTimeLeft = useCallback(() => {
        const difference = new Date(targetDate) - new Date();
        if (difference > 0) {
            return {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }
        return null; // Time's up
    }, [targetDate]);

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [calculateTimeLeft]);

    if (!timeLeft) {
        return <div className="countdown">We're officially married!</div>;
    }

    return (
        <div className="countdown">
            <div className="countdown-item">
                <span>{timeLeft.days}</span> <small>Days</small>
            </div>
            <div className="countdown-item">
                <span>{timeLeft.hours}</span> <small>Hours</small>
            </div>
            <div className="countdown-item">
                <span>{timeLeft.minutes}</span> <small>Minutes</small>
            </div>
            <div className="countdown-item">
                <span>{timeLeft.seconds}</span> <small>Seconds</small>
            </div>
        </div>
    );
};

export default Countdown;
